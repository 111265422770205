<template>
  <div class="assignment">
    <div class="dateInput">
      <div class="dateInput">
        <el-date-picker v-model="data_stamp" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp">
        </el-date-picker>
        <Page :current="taskListCuPage" :total="taskListTotal" simple @on-change="pageChange" style="margin-left: 10px" />
      </div>
      <div>
        <el-button type="primary" style="margin-right:30px" @click="toAddTask('/addCollectiveTask')">新建集体任务</el-button>
      </div>
    </div>
    <div class="content">
      <div class="content_left">
        <div class="top">
          <div v-if="taskList.length===0" class="flex_center_col_100">
            暂时没有任务
          </div>
          <div class="item" v-for="item in taskList" :key="item.id" @click="taskItemClick(item)" :class="item.id == listOperationPar.task_id ? 'active_color' : ''">
            <div class="date_box">
              <div class="avator">
                <div class="flex_center_col">
                  <el-avatar icon="el-icon-user-solid" size="small"></el-avatar>
                  <div class="name">{{ item.name }}</div>
                </div>
                <div>
                  <span>{{ item.startTime }}</span> -
                  <span>{{ item.endTime }}</span>
                </div>
              </div>
            </div>
            <div class="location">{{ item.name }}</div>
            <div class="site">
              任务进度：{{ item.draw_count }}/{{ item.count }}
            </div>
          </div>
        </div>
      </div>
      <div class="content_right">
        <div class="top">
          <div class="people_info" v-if="Object.keys(this.peopleInfo).length > 0">
            <div class="item">
              <div class="item_title">巡检人员</div>
              <!-- <div class="item_content">{{peopleInfo.name}}</div> -->
              <!-- <span>{{peopleInfo.users}}</span> -->
              <span class="item_content" v-for="item1 in peopleInfo.users" :key="item1.id">{{ item1.name }}</span>
              <span class="item_content" v-if="peopleInfo.users.length == 0">未指定</span>
            </div>
            <div class="item">
              <div class="item_title">巡检时间</div>
              <div class="item_content">
                {{ peopleInfo.startTime }} - {{ peopleInfo.endTime }}
              </div>
            </div>
            <div class="item">
              <div class="item_title">巡查地点</div>
              <div class="item_content">{{ peopleInfo.name }}</div>
            </div>
            <div class="item">
              <div class="item_title site">领取进度</div>
              <div class="item_content site">
                {{ peopleInfo.draw_count }}/{{ peopleInfo.count }}
              </div>
            </div>
            <div class="item">
              <div class="item_title">备注</div>
              <div class="item_content">
                {{ peopleInfo.remark || "未填写" }}
              </div>
            </div>
            <div>
              <el-image style="width: 80px; height: 80px;margin:10px" :src="item" :preview-src-list="fileImg" accordion :key="this" v-for="item in fileImg"></el-image>
            </div>
          </div>

          <div v-if="!peopleInfo.name" style="width:100%;height:100%;" class="flex_center_center">
            请选择需要查看的任务
          </div>
        </div>
        <div class="bottom">
          <!-- @node-click="infoNode" -->
          <div v-if="!peopleInfo.name" style="width:100%;height:100%;" class="flex_center_center">
            请选择需要查看的任务
          </div>
          <div v-else>
            <div class="siteClass">点位顺序</div>
            <el-timeline color="#0486FE">
              <el-timeline-item color="#0486FE" v-for="item in peopleInfo.sites" :key="item.id">
                {{ item.name }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <div class="popContainer" v-show="videoVisible" @click="closeVideoPage">
      <i class="el-icon-circle-close" @click="closeVideoPage"></i>
      <video class="video-js" controls preload="auto" data-setup="{}" ref="myVideo" style="width: 70vw; height: 80vh" :src="videoSrc"></video>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { task } from '../../utils/api';
import _ from 'lodash';
export default {
  name:'collectiveTask',
  data() {
    return {
      data_stamp: '',
      //第二天的时间戳 用于进行比较
      tomorrow_stamp: '',
      taskListPar: {
        date_stamp: '',
        scope: 2,
        page: 1,
        page_size: 10
      },
      taskListCuPage: 1,
      taskListTotal: 0,
      taskList: [],
      peopleInfo: {},
      count: 0,
      loading: false,
      listOperationPar: {
        task_id: '',
        page: 0,
        page_size: 10
      },
      listOperationParTol: 0,
      divHeight: 0,
      isLoading: true,
      commentList: [],
      commentListTotal: 0,
      srcList: [],
      videoSrc: '',
      videoVisible: false,
      patrolHistoryData: [],
      patrolHistory: {},
      rolling: true,
      site_logs: [],
      fileImg: [],
      defaultProps: {
        children: 'inspection_logs',
        label: 'name'
      },
      srcList1: []
    };
  },
  created() {
    this.data_stamp = new Date(new Date().toLocaleDateString()).getTime();
  },
  mounted() {},
  components: {},

  computed: {
    noMore() {
      return this.count >= 20;
    },
    disabled() {
      return this.loading || this.noMore;
    }
  },

  methods: {
    async getTaskList() {
      const res = await this.$http.get(
        task.listBoard + '?' + qs.stringify(this.taskListPar)
      );
      res.data.forEach(item => {
        item.startTime = this.$moment(
          item.prescribed_start_stamp * 1000
        ).format('HH:mm');
        item.endTime =
          item.prescribed_end_stamp > this.tomorrow_stamp
            ? '次日 ' +
              this.$moment(item.prescribed_end_stamp * 1000).format('HH:mm')
            : this.$moment(item.prescribed_end_stamp * 1000).format('HH:mm');
      });
      this.taskList = res.data;
      this.taskListTotal = res.total;
    },
    pageChange(e) {
      this.taskListPar.page = e;
      this.getTaskList();
    },
    taskItemClick(item) {
      if (this.listOperationPar.task_id === item.id) {
        return false;
      }
      this.loading = true;
      this.peopleInfo = item;
      this.peopleInfo.users = [];
      this.listOperationPar.task_id = item.id;
      this.listOperationPar.page = 0;
      this.commentList = [];
      //   this.loadMore();
      this.isLoading = false;
      this.getTaskInfo();
    },
    load: _.debounce(function(e) {
      if (
        e.target.scrollTop + e.target.clientHeight ===
        e.target.scrollHeight
      ) {
        this.loadMore();
        this.isLoading = false;
      }
    }, 100),
    // 滚动加载函数
    clickVideoSlef() {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
    },
    clickVideo(url) {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
      this.$refs.myVideo.src = url;
      this.$refs.myVideo.play();
      this.videoVisible = true;
    },
    closeVideoPage() {
      this.$refs.myVideo.pause();
      this.videoVisible = false;
    },
    // 这里不用做滚动加载了
    async getTaskInfo() {
      const res = await this.$http.get(
        task.boardInfo + '?id=' + this.peopleInfo.id
      );
      if (res.code === 0) {
        res.data.startTime = this.$moment(
          res.data.prescribed_start_stamp * 1000
        ).format('HH:mm');
        res.data.endTime =
          res.data.prescribed_end_stamp > this.tomorrow_stamp
            ? '次日 ' +
              this.$moment(res.data.prescribed_end_stamp * 1000).format('HH:mm')
            : this.$moment(res.data.prescribed_end_stamp * 1000).format(
                'HH:mm'
              );
        this.fileImg = [];
        res.data.files.forEach(fitem => {
          this.fileImg.push(fitem.url);
        });
        this.peopleInfo = res.data;
      }
    },
    toAddTask(url) {
      this.$router.push(url);
    },
    errorHandler() {
      return true;
    }
  },
  activated() {
    if (this.$route.query.date_stamps) {
      console.log('触发');
      if (
        this.$route.query.date_stamps.some(
          item => item == this.taskListPar.date_stamp
        )
      ) {
        this.getTaskList();
      }
    }
  },
  watch: {
    data_stamp(value) {
      if (!value) {
        return false;
      }
      // this.peopleInfo={}
      this.taskListPar.date_stamp = value / 1000;
      this.tomorrow_stamp = (value + 24 * 60 * 60 * 1000) / 1000;
      this.getTaskList();
    }
  }
};
</script>
<style lang="scss" scoped>
.assignment {
  width: 1200px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
  /deep/.custom-tree-node {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    align-items: center;
    min-height: 50px !important;
    .custom-tree-node-title {
      text-align: left;
      line-height: 100px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
    }
  }
  /deep/ .el-tree-node__content {
    min-height: 50px;
  }
  /deep/ .input_btn {
    display: flex;
    justify-content: space-between;
  }
  /deep/ .el-input {
    width: 300px;
  }
  /deep/ .listBox {
    display: flex;
    margin-top: 8px;
  }
  .dateInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    display: flex;
    margin-top: 8px;
    width: 100%;
    .content_left {
      width: 382px;
      margin-right: 8px;
      .top {
        width: 100%;
        height: 734px;
        // padding: 0 16px;
        background: #ffffff;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: auto;
        .item {
          display: flex;
          flex-direction: column;
          // justify-content: space-around;
          height: 111px;
          width: 100%;
          border-bottom: 1px solid #f4f4f4;
          line-height: 24px;
          padding: 0 16px;
          padding-top: 10px;
          .date_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            .avator {
              display: flex;
              align-items: center;
              width: 350px;
              justify-content: space-between;
              .name {
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-left: 16px;
              }
            }
            .date {
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            }
          }
          .location {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            padding-left: 44px;
          }
          .site {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #409eff;
            line-height: 24px;
            padding-left: 44px;
          }
        }
      }
    }
    .content_right {
      width: 778px;
      .top {
        display: flex;
        width: 778px;
        height: 259px;
        background: #ffffff;
        border-radius: 4px;
        padding: 16px;
        box-sizing: border-box;
        margin-bottom: 8px;
        .people_info {
          width: 746px;
          min-height: 227px;
          background: #ffffff;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding: 16px;
          margin-right: 10px;
          .item {
            display: flex;
            line-height: 28px;
            width: 275px;
            .item_title {
              width: 89px;
            }
            .item_content {
              width: 186px;
            }
            .site {
              font-weight: 500;
              color: #409eff;
              line-height: 24px;
            }
          }
        }
        .commont_list {
          width: 425px;
          height: 227px;
          background: #ffffff;
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          overflow: auto;
          padding: 16px;
          .commont_title {
            height: 20px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #333333;
            line-height: 20px;
            padding-bottom: 8px;
            position: sticky;
            top: 0;
            background-color: #ffffff;
          }
          .item {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 8px;
            .date_box {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 14px;
              .avator {
                display: flex;
                align-items: center;
                width: 393px;
                justify-content: space-between;
                margin-bottom: 8px;
                .name {
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 14px;
                  margin-left: 16px;
                }
              }
              .date {
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
              }
            }
            .location {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              margin: 20px;
            }
          }
        }
      }
      .bottom {
        width: 777px;
        height: 467px;
        background: #ffffff;
        border-radius: 4px;
        padding: 20px;
        overflow: auto;
        .siteClass {
          line-height: 30px;
          height: 50px;
        }
      }
    }
  }
  .ivu-page-simple .ivu-page-next,
  .ivu-page-simple .ivu-page-prev {
    height: 36px;
    display: flex;
    align-items: center;
    background: #f0f2f5;
    .ivu-icon {
      line-height: 37px;
      background: #f0f2f5;
    }
  }
}
li {
  list-style: none;
}
.infinite-list-wrapper {
  height: 100px;
}
.list {
  height: 200px;
  overflow: auto;
}
div.popContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.el-icon-video-play {
  position: absolute;
  top: -260%;
  left: 27%;
  font-size: 50px;
  color: #000000;
}
.mediaClass {
  display: flex;
  align-items: center;
  height: 100px;
  margin-top: 50px;
  .el-icon-video-play {
    position: absolute;
    top: -260%;
    left: 27%;
    font-size: 50px;
    color: #000000;
  }
}
</style>
